<template>
  <!-- 500 INTERNAL SERVER ERROR -->
  <div class="mx-auto">
    <h2>Oops. Something is broken.</h2>
    <p v-if="errors.length">
      {{ errors }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'errors',
    ]),
  },
};
</script>
